import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import { WinsData } from '../data/totalwins';
import { RosterData } from '../data/roster';
import { useNavigate } from 'react-router-dom';

const CirclePackWins = () => {
  const [combinedData, setCombinedData] = useState([]);
  const svgRef = useRef();
  
  const width = 800;
  const height = 600;

  const navigate = useNavigate();

  useEffect(() => {
    const brandLookup = new Map(RosterData.map(car => [car.Name, car.Brand]));
    
    // Filter out cars with 0 wins and then join the data
    const joinedData = WinsData.filter(carWins => carWins.Wins > 0)
                               .map(carWins => {
                                 const brand = brandLookup.get(carWins.Car) || 'Unknown';
                                 return { name: carWins.Car, brand: brand, value: carWins.Wins };
                               });
  
    setCombinedData(joinedData);
  }, []);

  useEffect(() => {
    if (combinedData.length > 0) {
      d3.select(svgRef.current).selectAll('*').remove();

      // Group data by brand
      const nestedData = d3.groups(combinedData, d => d.brand)
                           .map(([key, values]) => ({name: key, children: values}));

      const root = d3.hierarchy({name: 'root', children: nestedData})
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value);

      const color = d3.scaleOrdinal(d3.schemeCategory10);

      const pack = d3.pack()
        .size([width, height])
        .padding(3);

      const nodes = pack(root).descendants().slice(1);

      const svg = d3.select(svgRef.current);

      // Draw the circles
      const circles = svg.selectAll('circle')
      .data(nodes)
      .enter().append('circle')
      .attr('cx', d => d.x)
      .attr('cy', d => d.y)
      .attr('r', d => d.r)
      .attr('fill', d => d.depth === 1 ? color(d.data.name) : 'white')
      .attr('stroke', d => d.depth === 1 ? 'black' : null)
      .style('cursor', d=> d.depth === 2 ? 'pointer' : null)
      .on('click', (event, d) => {
        if (d.depth === 2) { // Assuming depth 2 is where car nodes are
          navigate(`/roster/${d.data.name}`); // Step 3: Navigate on click
        }
    });
    // Event listeners for the car circles only (depth level 2)
    circles.filter(d => d.depth === 2) // Filter for car nodes
      .on('mouseover', function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr('r', d.r * 1.1); // Increase the radius by 10% on hover for car circles
  
        d3.select("#circlepack-tooltip")
          .style("opacity", 1)
          .style("left", `${event.pageX}px`)
          .style("top", `${event.pageY}px`);
        d3.select("#circlepack-tooltip-car").text(d.data.name);
        d3.select("#circlepack-tooltip-brand").text(d.data.brand);
        d3.select("#circlepack-tooltip-wins").text(d.data.value);
      })
      .on('mousemove', function (event) {
        d3.select("#circlepack-tooltip")
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY + 10}px`);
      })
      .on('mouseout', function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr('r', d.r)
          .attr('fill', d => d.depth === 1 ? color(d.data.name) : 'white'); // Return to original radius for car circles
  
        d3.select("#circlepack-tooltip").style("opacity", 0);
      });



      // Add text labels for the cars if there is space
      svg.selectAll('text.carLabel')
        .data(nodes.filter(d => d.depth === 2)) // Filter for car nodes
        .enter().append('text')
        .classed('carLabel', true)
        .attr('x', d => d.x)
        .attr('y', d => d.y)
        .text(d => d.data.name)
        .attr('text-anchor', 'middle')
        .style('fill', 'black')
        .style('font-size', '10px')
        .style('visibility', d => d.r > 15 ? 'visible' : 'hidden');
    }
  }, [combinedData]);

  return (
    <>
      <div id="circlepack-tooltip" className="circlepack-tooltip" style={{ opacity: 0 }}>
        <p><strong>Car:</strong> <span id="circlepack-tooltip-car"></span></p>
        <p><strong>Brand:</strong> <span id="circlepack-tooltip-brand"></span></p>
        <p><strong>Wins:</strong> <span id="circlepack-tooltip-wins"></span></p>
      </div>
      <svg ref={svgRef} width={width} height={height}></svg>
    </>
  );
};

export default CirclePackWins;



