import Header from "../../components/Header";
import TotalWinsBarChart from "../../components/TotalWinsBar";
import React, { useState, useEffect } from "react";
import { Box, Slider, Typography } from "@mui/material";
import { WinsData as data } from "../../data/totalwins"; // Import your data here
import CirclePackWins from "../../components/CirclePack";

const WinsBar = () => {
  useEffect(() => {
    // Target the first element with the 'content' class
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      contentElement.scrollTop = 0;
    }
  }, []);
    // Define component state for filtering
  const [minWins, setMinWins] = useState(10); // Default minimum wins
  const maxWins = Math.max(...data.map((car) => car.Wins));

  return (
    <Box m="20px" paddingBottom={200}>
      <Header title="Total Wins" subtitle="Victories in official tournament play by car. Only cars that have won at least 1 heat are included." />
      <Box width = "30%">
      <Slider
  value={minWins}
  onChange={(e, newValue) => setMinWins(newValue)}
  min={1} // Set the minimum value
  max={maxWins} // Set the maximum value
  step={1} // Set the step increment
  valueLabelDisplay="auto" // Display the current value above the slider
  valueLabelFormat={(value) => `${value} Wins`}
  color= "secondary" // Format the value label
/>
<Typography gutterBottom>Minimum Wins: {minWins}</Typography>
</Box>
      <Box height="75vh">
        <TotalWinsBarChart minWins={minWins} />
      </Box>
      <Box marginBottom="-50px">
        <Typography variant="h2" fontWeight="bold">
          Total Wins by Diecast Brand
        </Typography>
      </Box>
      <Box>
        <CirclePackWins />
      </Box>
    </Box>
  );
};

export default WinsBar;