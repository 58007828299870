import Header from "../../components/Header";
import TotalWinsBarChart from "../../components/TotalWinsBar";
import React, { useState, useEffect } from "react";
import { Box, Slider, Typography } from "@mui/material";
import { WinsData as data } from "../../data/totalwins"; // Import your data here
import LanesChart from "../../components/LaneChart";
import { tourneyData } from "../../data/tourneys"; // Import your data here
import LanesBoxPlot from "../../components/LaneDist";

const LanesPage = () => {
  useEffect(() => {
    // Target the first element with the 'content' class
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      contentElement.scrollTop = 0;
    }
  }, []);
  // Define component state for filtering

  return (
    <Box m="20px" paddingBottom={200}>
      <Header title="Lane Analytics" subtitle="Examine performance variations that may be attributed to track deficiencies on each lane." />

      <Box height="75vh">
        <LanesChart />
        <LanesBoxPlot />
      </Box>
    </Box>
  );
};

export default LanesPage