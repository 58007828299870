import React, { useState } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useNavigate } from 'react-router-dom';
import * as d3 from 'd3';
import { tourneyStatsData } from '../data/tourneystats';
import { champData } from '../data/tourneywintotals';
import { tokens } from '../theme';

const carDataMap = d3.group(tourneyStatsData, d => d.Car);

const chartData = Array.from(carDataMap, ([Car, group]) => ({
  Car,
  Championships: d3.sum(group, d => d.IsChampion),
}));

const TotalTourneysBarChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  champData.sort((a, b) => b.Championships - a.Championships);

  const margin = isSmallScreen
    ? { top: 20, right: 20, bottom: 100, left: 40 }
    : { top: 50, right: 130, bottom: 150, left: 60 };

  return (
    <ResponsiveBar
      data={champData}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        tooltip: {
          container: {
            background: colors.primary[400],
            color: colors.greenAccent[100],
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      indexBy="Car"
      keys={['Championships']}
      groupMode="grouped"
      margin={margin}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={(bar) => colors.greenAccent[600]}
      borderColor={{
        from: 'color',
        modifiers: [['darker', '1.6']],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: isSmallScreen ? 90 : -90, // Vertical on mobile
        legendOffset: isSmallScreen ? 36 : 90,
      }}      
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard || isSmallScreen ? undefined : 'Total Championships',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      role="application"
      barAriaLabel={(e) => {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
      onClick={(bar) => {
        navigate(`/roster/${bar.data.Car}`);
      }}
    />
  );
};

export default TotalTourneysBarChart;
