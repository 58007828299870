import React, { useMemo, useState, useEffect } from "react";
import * as d3 from "d3";
import AxisBottom from "./AxisBottom";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";

const BUCKET_PADDING = 4;
const DESIRED_BIN_WIDTH = 0.02;
const MARGIN = { top: 30, right: 30, bottom: 50, left: 50 };

type HistogramProps = {
  width: number;
  height: number;
  data: number[];
};

const Histogram = ({ width, height, data }: HistogramProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hoveredBin, setHoveredBin] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dataRange = d3.extent(data);
  const numBins = Math.ceil((dataRange[1] - dataRange[0]) / DESIRED_BIN_WIDTH);
  const thresholds = Array.from({ length: numBins + 1 }, (_, i) =>
    dataRange[0] + i * (dataRange[1] - dataRange[0]) / numBins
  );

  const xScale = useMemo(() => {
    return d3.scaleLinear().domain(dataRange).range([10, width - 10]);
  }, [data, width]);

  const buckets = useMemo(() => {
    const binGenerator = d3.histogram().value((d) => d).thresholds(thresholds);
    return binGenerator(data);
  }, [data, thresholds]);

  const yScale = useMemo(() => {
    const max = Math.max(...buckets.map((bucket) => bucket?.length));
    return d3.scaleLinear().range([height, 0]).domain([0, max]);
  }, [data, height]);

  const median = d3.median(data);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setIsLoaded(true);
    }, 500);

    return () => clearTimeout(animationTimeout);
  }, []);

  const handleMouseOver = (event, bucket) => {
    setHoveredBin(bucket);

    // Calculate the initial position of the tooltip based on the mouse's position
    const xPosition = event.clientX;
    const yPosition = event.clientY;

    // Adjust the tooltip's position as needed
    setTooltipPosition({ left: xPosition + 10, top: yPosition - 40 });
  };

  const handleMouseMove = (event) => {
    // If the tooltip is active, update its position as the mouse moves
    if (tooltipPosition) {
      const xPosition = event.clientX;
      const yPosition = event.clientY;

      // Apply a CSS transition to the tooltip's position
      setTooltipPosition((prevPosition) => {
        return {
          left: xPosition + 10,
          top: yPosition - 40,
          transition: "left 0.2s, top 0.2s",
        };
      });
    }
  };

  const handleMouseLeave = () => {
    setHoveredBin(null);
    setTooltipPosition(null);
  };

  const allRects = buckets.map((bucket, i) => {
    return (
      <g
        key={i}
        onMouseOver={(e) => handleMouseOver(e, bucket)}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{ cursor: "pointer" }}
      >
        <rect
          fill="#69b3a2"
          stroke="#69b3a2"
          x={xScale(bucket.x0) + BUCKET_PADDING / 2}
          width={xScale(bucket.x1) - xScale(bucket.x0) - BUCKET_PADDING}
          y={isLoaded ? yScale(bucket.length) : height}
          height={isLoaded ? height - yScale(bucket.length) : 0}
          style={{
            transition: "y .5s ease-in-out, height .5s ease-in-out",
          }}
          className="histogram-bar"
        />
      </g>
    );
  });

  const tooltip = hoveredBin && tooltipPosition && (
    <div
      className="tooltip"
      style={{
        left: tooltipPosition.left,
        top: tooltipPosition.top,
        transition: tooltipPosition.transition,
        background: colors.primary[400], // Set the background color here
        color: "#fff", // Optional: Set the text color
        padding: "8px", // Optional: Add some padding for better aesthetics
      }}
    >
      <div>
        <strong>Range:</strong> {hoveredBin.x0.toFixed(2)} - {hoveredBin.x1.toFixed(2)}s
      </div>
      <div><strong>Count:</strong> {hoveredBin.length}</div>
    </div>
  );
  

  return (
    <div className="histogram-container">
      <h2>Trips down track by time</h2>
      <svg
        width={width}
        height={height + MARGIN.bottom}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        {allRects}

        <g transform={`translate(0, ${height + 51 - MARGIN.bottom})`}>
          <AxisBottom xScale={xScale} pixelsPerTick={40} />
        </g>

        <line
          x1={xScale(median)}
          x2={xScale(median)}
          y1={0}
          y2={height}
          stroke="blue"
          strokeWidth={2}
          strokeDasharray="5 5"
        />
      </svg>

      {tooltip}
    </div>
  );
};

export default Histogram;
