import React, { useEffect } from 'react';
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { updatedRankingsData } from "../../data/updatedrankings";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import { RosterData } from "../../data/roster";

const mergedData = updatedRankingsData.map((ranking, index) => {
  const rosterEntry = RosterData.find(roster => roster.Name === ranking.Car);
  return {
    id: index, // Generate a unique id using the index
    ...ranking,
    Brand: rosterEntry?.Brand,
    Make: rosterEntry?.Make,
    Model: rosterEntry?.Model,
    RiskLevel: ranking.Rank // Add this line
  };
});

console.log(mergedData)


const Rankings = () => {
  useEffect(() => {
    // Target the first element with the 'content' class
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      contentElement.scrollTop = 0;
    }
  }, []);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const getRiskLevelColor = (rank) => {
    if (rank >= 1 && rank <= 72) {
      // Progress from dark green to dark red
      const greenShade = Math.floor(255 - (rank - 1) * (255 / 71));
      const redShade = 255 - greenShade;
      const color = `rgb(${redShade}, ${greenShade}, 0)`;
      return `rgb(${redShade}, ${greenShade}, 0)`;
    } else {
      return "black"; // Black for values higher than 72
    }
  };

  const desktopColumns = [
    {
        field: "Car",
        headerName: "Name",
        flex: 1,
        cellClassName: "name-column--cell",
        width: 120,
        renderCell: (params) => (
          <Link
          to={`/roster/${params.value}`}
          style={{
            textDecoration: "none", // Remove underline
            color: "inherit", // Inherit text color from the parent
            // Add other styling as needed
          }}
        >
          {params.value}
        </Link>
        ),
      },
      {
       field: "Brand",
       headerName: "Brand",
       width: 120 ,
       hide: isMobile,
      },
      {field: "Make",
      headerName: "Make",
    hide: isMobile,},
      {field: "Model",
      headerName: "Model",
    hide: isMobile,},
    {
      field: "Rank",
      headerName: "Rank",
      width: 75,
    },
    {
      field: "Time",
      headerName: "Time",
      width: 75,
    },
    {
      field: "Method",
      headerName: "Method",
      width: 75,
    },
    {
      field: "RiskLevel",
      headerName: "Risk Level",
      renderCell: (params) => (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: getRiskLevelColor(params.value),
          }}
        ></div>
      ),
    },
  ];

    // Filter out less important columns for mobile view
    const mobileColumns = desktopColumns.filter(column => 
      column.field !== 'Brand' && column.field !== 'Make' && column.field !== 'Model' && column.field !== 'Time' && column.field !== 'Method'
    );

     // Use either mobileColumns or desktopColumns based on screen size
  const columnsToUse = isMobile ? mobileColumns : desktopColumns;

  return (
    <Box m="20px">
      <Header
        title="Rankings"
        subtitle="Rankings are updated after every tournament. For cars who have at least 8 tournament races, the average time of their last 8 races is used. If a car has not had 8 tournament races, that car's time trials are used. Only the fastest 72 cars are eligible for tournament racing."
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <div style={{ width: "100%", margin: "0px 90px 90px 0px" }}>
          <DataGrid
            disableColumnResize={false}
            rows={mergedData}
            columns={columnsToUse}
            sortModel={[
              {
                field: "Rank",
                sort: "asc", // 'desc' for descending order
              },
            ]}
          />
        </div>
      </Box>
    </Box>
  );
};

export default Rankings;
