import React, { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  useEffect(() => {
    // Target the first element with the 'content' class
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      contentElement.scrollTop = 0;
    }
  }, []);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions Page" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What is your racing setup?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Here at the Diecast Database, we race on a scale quarter mile track
            (21.7 feet). We use the Hot Wheels 6 Lane Raceway as our starting
            gate, and a <a href = "http://www.playrobotics.com" style={{ textDecoration: 'none'}}>PlayRobotics</a> 6 Lane Raceway Timing System for time
            tracking.<p></p>

            <img src ="track.jpeg" alt="track" width="500" height="300"></img>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How do your tournaments work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            All tournaments are 72 car tournaments, limited to the fastest 72
            cars in our collection, which is dynamically updated at all times
            through our rankings system. All tournaments are three rounds. The
            first round consists of 12 heats of 6 cars each, with each car
            possessing the fastest time in each heat advancing to the second
            round. In the second round, there are two heats of 6 cars each, with
            the fastest time from each advancing to the championship. The two
            cars in the championship are placed in lanes 2 and 5 for their first
            trip down the track, and then they have those lanes switched for
            their second time down the track. If the same car wins both races,
            that car is the champion. If those races are split, a third heat is
            run with the cars back in their original lanes, and the winner of
            that race is the champion. All times for all cars in all heats are
            logged.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How does your ranking system work?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
The ranking system is in place to ensure our fastest 72 cars are the cars in tournament play. Car speed can vary over time and usage, so this system accounts for changes in the roster's performance. Any car that has 8 or more trips down the track in tournament play has their 8 recent most runs averaged and ranked against the field. New cars don't have the chance to race in tournament play, so their time trial times are used for ranking purposes. Once a car has 8 runs down the track, they are evaluated based on tournament performance rather than time trials. See more on the Rankings page.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What are time trials?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
Time trials refers to the process that all rostered cars undergo, where without any other cars on the track, the car cars takes 8 runs down the track. Each car takes 2 runs down lanes 2 and 5, and 1 run down the rest of the lanes. The car's slowest time is removed, and the median is calculated from the remaining 7 values. This median is used to determine whether a new car qualifies for tournament play, which is limited to our fastest 72 cars.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Do you use graphite on your cars?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
We don't use graphite to race.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;