import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DashBox from "../../components/DashBox";
import { tourneyData } from "../../data/tourneys";
import { RosterData } from "../../data/roster";
import { tourneyStatsData } from "../../data/tourneystats";
import DashboardLine from "../../components/DashboardLine";
import TenTourneys from "../../components/TenTourneys";
import AtRisk from "../../components/AtRisk";
import OnDeck from "../../components/OnDeck";
import TotalTripsBarChart from "../../components/TripsCountBar";
import { Helmet } from 'react-helmet';

const Dashboard = () => {
useEffect(() => {
  // Target the first element with the 'content' class
  const contentElement = document.querySelector('.content');
  if (contentElement) {
    contentElement.scrollTop = 0;
  }
}, []);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Calculate the maximum tourneyID directly
  const mostRecentTourney = tourneyData.reduce(
    (maxTourney, tourney) => Math.max(maxTourney, tourney.tourneyid),
    -Infinity
  );

  // Filter the dataset to include only entries where Time is not NaN
  const filteredData = tourneyData.filter(
    (entry) => !isNaN(parseFloat(entry.Time))
  );

  // Get the count of unique entries
  const uniqueEntryCount = filteredData.length;

  // Get the highest value of CarID from Roster Data
  const numberOfCars = RosterData.reduce(
    (maxCar, car) => Math.max(maxCar, car.id),
    -Infinity
  );

  // Create a Set to store unique car names
  const uniqueCarNames = new Set();
  tourneyStatsData.forEach((entry) => {
    uniqueCarNames.add(entry.Car);
  });

  // Get the count of unique car names
  const uniqueChampCount = uniqueCarNames.size;

  const lineChartData = filteredData.reduce((result, entry) => {
    const { tourneyid, Time } = entry;
  
    // Initialize an entry for the tourneyid if it doesn't exist
    if (!result[tourneyid]) {
      result[tourneyid] = {
        tourneyid,
        medianTime: [parseFloat(Time)],
        highestTime: parseFloat(Time),
        lowestTime: parseFloat(Time),
      };
    } else {
      // Update the median, highest, and lowest Time values for the tourneyid
      result[tourneyid].medianTime.push(parseFloat(Time));
      result[tourneyid].medianTime.sort((a, b) => a - b); // Sort for median calculation
      result[tourneyid].highestTime = Math.max(
        result[tourneyid].highestTime,
        parseFloat(Time)
      );
      result[tourneyid].lowestTime = Math.min(
        result[tourneyid].lowestTime,
        parseFloat(Time)
      );
    }
  
    return result;
  }, {});
  
  // Calculate the median Time value for each tourneyid
  for (const tourneyid in lineChartData) {
    const entry = lineChartData[tourneyid];
    const { medianTime } = entry;
    if (medianTime.length % 2 === 0) {
      const mid = medianTime.length / 2;
      entry.medianTime = (medianTime[mid - 1] + medianTime[mid]) / 2;
    } else {
      const mid = Math.floor(medianTime.length / 2);
      entry.medianTime = medianTime[mid];
    }
  }
  
  const lineChartDataArray = Object.values(lineChartData);

  //Declare tenTourneyData

  const tenTourneyData = tourneyStatsData.reduce((uniqueEntries, entry, index) => {
    const tourneyID = entry.tourneyid;
    // Check if this TourneyID is not already in the uniqueEntries array.
    if (!uniqueEntries.some(item => item.TourneyID === tourneyID)) {
      uniqueEntries.push({
        id: index + 1,
        TourneyID: tourneyID,
        Car: entry.Car,
      });
    }
    return uniqueEntries;
  }, []);

  //Create animation function

const easeOutQuad = t => t * ( 2 - t );
const frameDuration = 1000 / 60;

const CountUpAnimation = ( { children, duration = 2000 } ) => {
	const countTo = parseInt( children, 10 );
	const [ count, setCount ] = useState( 0 );

	useEffect( () => {
		let frame = 0;
		const totalFrames = Math.round( duration / frameDuration );
		const counter = setInterval( () => {
			frame++;
			const progress = easeOutQuad( frame / totalFrames );
			setCount( countTo * progress );

			if ( frame === totalFrames ) {
				clearInterval( counter );
			}
		}, frameDuration );
	}, [] );

	return Math.floor( count );
};


return (
  <Box sx={{ padding: '20px' }}>
     <Helmet>
        <title>Diecast Database Race League - Dashboard</title>
        <meta name="description" content="Explore comprehensive statistics and data on diecast car racing performance in the Diecast Database Race League." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* You can add more meta tags here as needed */}
      </Helmet>
    {/* HEADER */}
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: { xs: 'column', sm: 'row' },
      gap: { xs: '10px', sm: '0px' },
      marginBottom: '20px', // Add margin at the bottom for small devices
    }}>
      <Header
        title="Diecast Database Race League"
        subtitle="Data-tracked 1/4 mile scale racing, to entertain you and help you find fast cars."
      />
    </Box>

    {/* DashBoxes */}
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
        gridAutoRows: 'auto',
        gap: '20px',
        marginBottom: '20px',
      }}
    >
      {/* DashBox for Tournaments Run */}
      <Box
        sx={{
          backgroundColor: colors.greenAccent[400],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        <DashBox
          title={<CountUpAnimation>{mostRecentTourney}</CountUpAnimation>}
          subtitle="Tournaments Run"
        />
      </Box>

      {/* DashBox for Trips Down the Track */}
      <Box
        sx={{
          backgroundColor: colors.greenAccent[400],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        <DashBox
          title={<CountUpAnimation>{uniqueEntryCount}</CountUpAnimation>}
          subtitle="Trips down the track"
        />
      </Box>

      {/* DashBox for Cars Rostered */}
      <Box
        sx={{
          backgroundColor: colors.greenAccent[400],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        <DashBox
          title={<CountUpAnimation>{numberOfCars}</CountUpAnimation>}
          subtitle="Cars rostered"
        />
      </Box>

      {/* DashBox for Champions Crowned */}
      <Box
        sx={{
          backgroundColor: colors.greenAccent[400],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        <DashBox
          title={<CountUpAnimation>{uniqueChampCount}</CountUpAnimation>}
          subtitle="Champions crowned"
        />
      </Box>
    </Box>

    <DashboardLine lineChartData={lineChartDataArray} />

    <Box sx={{
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  justifyContent: 'space-between',
  gap: { xs: '10px', md: '20px' },
  marginBottom: '20px',
  '& > *': {
    width: '100%', // Ensure child components take full width on mobile
    maxWidth: '100%', // Prevent any max-width from constraining the components
  },
}}>
      <TenTourneys tenTourneyData={tenTourneyData} sx={{ flex: 1 }} />
      <AtRisk sx={{ flex: 1 }} />
      <OnDeck sx={{ flex: 1 }} />
    </Box>

    <Box sx={{
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      marginTop: '80px',
    }}>
      <TotalTripsBarChart sx={{ width: '100%' }} />
    </Box>
  </Box>
);
  };

export default Dashboard;

