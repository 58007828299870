import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';
import { Link } from 'react-router-dom';
import { updatedRankingsData } from '../data/updatedrankings';

const OnDeck = ({}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const onDeckData = updatedRankingsData.filter((entry) => entry.Rank >= 73 && entry.Rank <= 77);

  const columns = [
    {
      field: "Car",
      headerName: "Car",
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Link
          to={`/roster/${params.value}`}
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'Rank',
      headerName: 'Rank',
      width: 130,
    },
    { field: 'Time', headerName: 'Time', width: 130 },
  ];

  const rows = onDeckData.map((entry, index) => ({
    id: index + 1,
    Car: entry.Car,
    Rank: entry.Rank,
    Time: entry.Time,
  }));

  return (
    <div style={{ height: 400, marginTop: "30px" }} sx={{ width: { xs: '100%', md: '33%' } }}>
      <h2>On Deck</h2>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        sortModel={[
            {
              field: 'Rank',
              sort: 'asc', // 'desc' for descending order
            },
        ]}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            display: "none"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      />
    </div>
  );
};

export default OnDeck;