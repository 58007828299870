import React, { useState, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";

function formatDataForNivo(lineChartData, colors, limitData) {
  if (!Array.isArray(lineChartData)) {
    return [];
  }

  const slicedData = limitData ? lineChartData.slice(-10) : lineChartData;

  const greenAccent = colors.greenAccent[500];
  const blueAccent = colors.blueAccent[500];

  return [
    {
      id: "Median Time",
      data: slicedData.map((entry) => ({
        x: entry.tourneyid,
        y: entry.medianTime,
      })),
      color: greenAccent,
    },
    {
      id: "Highest Time",
      data: slicedData.map((entry) => ({
        x: entry.tourneyid,
        y: entry.highestTime,
      })),
      color: blueAccent,
    },
    {
      id: "Lowest Time",
      data: slicedData.map((entry) => ({
        x: entry.tourneyid,
        y: entry.lowestTime,
      }))    }
  ];
}

const DashboardLine = ({ lineChartData }) => {
  const [isMobile, setIsMobile] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const titleStyle = {
    marginBottom: "-15px",
  };

  useEffect(() => {
    // Update the state based on screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 768px can be adjusted as per your breakpoint
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    // Pass the isMobile state to formatDataForNivo
    const data = formatDataForNivo(lineChartData, colors, isMobile);

    const mobileXAxisRange = isMobile && data.length > 0 ? {
      min: data[0].data[0].x,
      max: data[0].data[data[0].data.length - 1].x,
    } : { min: 'auto', max: 'auto' };

  return (
    <div style={{ height: "400px" }}>
      <h2 style={titleStyle}>High, Low, and Median Tournament Times</h2>
      <ResponsiveLine
        data={data}
        theme={{
            axis: {
                domain: {
                  line: {
                    stroke: colors.grey[100],
                  },
                },
                legend: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                ticks: {
                  line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: colors.grey[100],
                  },
                },
              },
              tooltip: {
                  container: {
                    background: colors.primary[400], // Change 'your-color-here' to your desired background color
                    color: colors.greenAccent[100], // Change 'white' to your desired text color
                  },
                },
              legends: {
                text: {
                  fill: colors.grey[100],
                },
              },   }}
        colors = {{ scheme : 'category10' }}
        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
   xScale={{ type: "linear", ...mobileXAxisRange }}        yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
        axisTop={null}
        axisRight={null}
        animate= {true}
        motionConfig = "wobbly"
        axisBottom={{
          legend: "Tourney ID",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          legend: "Time",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        enableSlices="x"
        pointSize={8}
        pointBorderWidth={2}
        pointLabel="y"
        useMesh={true}
      />
    </div>
  );
};

export default DashboardLine;
