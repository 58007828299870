import React from "react";

const TrophyImage = ({ championName }) => {
  const championNameStyle = {
    fontSize: "20px", // Adjust the font size as needed
  };

  return (
    <div>
      <img
        src="/goldtrophy.png" // Assuming your image is in the public directory
        alt="Gold Trophy"
        width="100"
        height="100"
      />
      {/* Apply the font size style to the champion's name */}
      <p style={championNameStyle}><strong>Champion:</strong> {championName}</p>
    </div>
  );
};

export default TrophyImage;

