import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';
import { Link } from 'react-router-dom';
import { updatedRankingsData } from '../data/updatedrankings';

const AtRisk = ({}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const last10Ranked = updatedRankingsData.filter((entry) => entry.Rank >= 63 && entry.Rank <= 72);

  const columns = [
    {
      field: "Car",
      headerName: "Car",
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Link
          to={`/roster/${params.value}`}
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'Rank',
      headerName: 'Rank',
      width: 130,
      cellClassName: (params) => {
        const rank = params.value;
        const minRank = 63; // Minimum Rank value
        const maxRank = 72; // Maximum Rank value

        // Calculate a ratio based on the rank within the range
        const ratio = (rank - minRank) / (maxRank - minRank);

        // Define the gradient background color from light orange to deep red
        const red = Math.round(255 - ratio * (255 - 255));
        const green = Math.round(165 - ratio * (165 - 0));
        const blue = 0;

        return { 'background': `rgb(${red}, ${green}, ${blue})` };
      },
    },
    { field: 'Time', headerName: 'Time', width: 130 },
  ];

  const rows = last10Ranked.map((entry, index) => ({
    id: index + 1,
    Car: entry.Car,
    Rank: entry.Rank,
    Time: entry.Time,
  }));

  return (
    <div style={{ height: 400, marginTop: "30px" }} sx={{ width: { xs: '100%', md: '33%' } }}>
      <h2>At Risk</h2>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        sortModel={[
            {
              field: 'Rank',
              sort: 'desc', // 'desc' for descending order
            },
        ]}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            width: "0px",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            display: "none"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      />
    </div>
  );
};

export default AtRisk;


