import React, { useEffect } from 'react';
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { RosterData } from "../../data/roster";
import Header from "../../components/Header";
import { Link } from "react-router-dom";

const Roster = () => {
  useEffect(() => {
    // Target the first element with the 'content' class
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      contentElement.scrollTop = 0;
    }
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const colors = tokens(theme.palette.mode);

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      flex: isSmallScreen ? 0 : 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Link to={`/roster/${params.value}`} style={{ textDecoration: "none", color: "inherit" }}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "Make",
      headerName: "Make",
      width: isSmallScreen ? 100 : 130,
      headerAlign: "left",
      align: "left",
      hide: isSmallScreen,
    },
    {
      field: "Model",
      headerName: "Model",
      width: isSmallScreen ? 100 : undefined,
      flex: isSmallScreen ? 0 : 1,
    },
    {
      field: "Brand",
      headerName: "Brand",
      width: isSmallScreen ? 100 : undefined,
      flex: isSmallScreen ? 0 : 1,
    },
  ];

  return (
    <Box m="20px">
      <Header title="Roster" subtitle="Meet the cars that make up the league" />
      <Box
        sx={{
          height: isSmallScreen ? 'auto' : '75vh',
          width: '100%',
          marginTop: 2,
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={RosterData}
          columns={columns}
          pageSize={isSmallScreen ? 5 : 10}
          // Consider other props such as rowHeight and headerHeight if needed for small screens
        />
      </Box>
    </Box>
  );
};

export default Roster;
