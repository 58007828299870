import React, { useRef, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RosterData } from "../data/roster";
import { WinsData } from "../data/totalwins";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../theme";
import StatBox from "../components/StatBox";
import Histogram from "./Histogram";
import { kernelDensityEstimator, kernelEpanechnikov } from "../utils/utils";
import { tourneyData } from "../data/tourneys";
import * as d3 from "d3";
import { champData } from "../data/tourneywintotals";
import TripsLine from "./TripsLine";
import LastTenTable from "./LastTenTable";
import { Helmet } from 'react-helmet';

const CarTemplate = () => {
  useEffect(() => {
    // Target the first element with the 'content' class
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      contentElement.scrollTop = 0;
    }
  }, []);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { carName } = useParams(); // Get the car name from the URL params

  // Filter the tourney data for the specific car and exclude "DNF" and "CE"
  const carTourneyData = tourneyData
    .filter((item) => item.Car === carName)
    .filter((item) => item.Time !== "DNF" && item.Time !== "CE");

  // Extract the time values, converting valid times to numbers and treating others as NaN
  const timeValues = carTourneyData.map((item) => {
    const numericTime = parseFloat(item.Time);
    return isNaN(numericTime) ? null : numericTime; // Use null for non-numeric values
  });

  // Calculate the median value of timeValues if it's not empty
  const medianTime =
    timeValues && timeValues.length > 0
      ? d3.median(timeValues.filter((value) => value !== null))
      : null;

  // Check if medianTime is a number before rounding it
  const roundedMedianTime =
    medianTime !== null && !isNaN(medianTime) ? medianTime.toFixed(3) : null;

  // Create a data object for the density plot
  const densityPlotData = useMemo(() => ({
    width: isMobile ? 300 : 500,
    height: isMobile ? 200 : 300,
    data: timeValues,
  }), [timeValues, isMobile]);

    // Create the data for the trips line chart
    const tripLineData = timeValues.map((value, index) => ({
      time: value,
      tripID: index + 1, // Start with 1 and increment by 1 for each record
    }));
  
      // Create a data object for the density plot
      const linePlotData = useMemo(() => ({
        width: isMobile ? 300 : 500,
        height: isMobile ? 300 : 400,
        data: tripLineData,
      }), [tripLineData, isMobile]);

  // Find the car data in RosterData based on the carName
  const car = RosterData.find((item) => item.Name === carName);

  if (!car) {
    return <div>Car not found</div>;
  }

  // Find the car's wins data in your dataset
  const carWins = WinsData.find((item) => item.Car === carName);

  // Access the wins count and Rank from the dataset
  const totalWins = carWins ? carWins.Wins : 0;
  const winsRank = carWins ? carWins.Rank2 : "N/A"; // Use "N/A" as the default value if Rank2 is not found

  // Find the car's tournament wins data (count and rank) in your dataset
  const tourneyWins = champData.find((item) => item.Car === carName);

  // Access the tournament wins count and Rank from the dataset
  const champWins = tourneyWins ? tourneyWins.Championships : 0;
  const champRank = tourneyWins ? tourneyWins.Rank2 : "N/A"; // Use "N/A" as the default value if Rank2 is not found

  // Access the number of trips down the track from the dataset
  // Calculate the number of values in timeValues
  const timesDownTrack = timeValues.filter((value) => value !== null).length;
  //Filter tourneyData for the last 10 runs of the specific car

  const carLastTen = tourneyData
  .filter((item) => item.Car === carName)
  .filter((item) => item.Time !== "DNF" && item.Time !== "CE")
  .slice(-10) // Get the 10 most recent entries
  .reverse(); //

  const carLastTenWithMedianDiff = carLastTen.map((entry, index) => {
    const timeValue = parseFloat(entry.Time);
  
    // Calculate the difference between timeValue and the car's median time
    const medianDiff = isNaN(timeValue) || isNaN(medianTime)
      ? null
      : (timeValue - medianTime).toFixed(3);
  
    return {
      id: index + 1,
      ...entry,
      MedianDiff: medianDiff, // Add the MedianDiff property to the entry
    };
  });

  const carLastTen2 = carLastTenWithMedianDiff.map((entry, index) => ({
    id: index + 1, // You can use a more unique ID generation logic if needed
    ...entry, // Spread the existing data
  }));

  const numericTimeCountsByCar = d3.group(tourneyData, (d) => d.Car);

const timeCountsResult = Array.from(numericTimeCountsByCar, ([car, data]) => ({
  Car: car,
  Count: d3.sum(data, (d) => !isNaN(parseFloat(d.Time) && d.Time !== 'DNF' && d.Time !== 'CE')),
}));

const sortedData = timeCountsResult.slice().sort((a, b) => b.Count - a.Count);

// Add the "Rank" property to each item
sortedData.forEach((item, index) => {
  item.Rank = index + 1; // Rank starts from 1
});

// Find the specific car's rank in the sorted data
const carRankEntry = sortedData.find((item) => item.Car === carName);

// Extract the rank value
const tripsRank = carRankEntry ? carRankEntry.Rank : "N/A";

// Function to convert a number to its ordinal representation
const getOrdinal = (number) => {
  const suffixes = ['st', 'nd', 'rd'];
  const lastDigit = number % 10;
  const suffix = lastDigit >= 1 && lastDigit <= 3 && number !== 11 && number !== 12 && number !== 13 ? suffixes[lastDigit - 1] : 'th';
  return number + suffix;
};

const ordinalRank = getOrdinal(tripsRank);

  return (
    <div>
      {car && (
      <Helmet>
        <title>{`${car.Brand} ${car.Make} ${car.Model} - Car Performance Details`}</title>
        {/* Other Helmet tags here */}
        <meta name="description" content={`Detailed performance data and statistics for the ${car.Brand} ${car.Make} ${car.Model}, including wins, average times, and more.`} />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="author" content="Diecast Database" />
    <meta name="language" content="English" />
      </Helmet>
    )}
      <h1 style={{ marginLeft: "10px" }}>{car.Name}</h1>
      <Card variant="outlined">
  <CardContent>
    <Grid container spacing={isMobile ? 1 : 2}>
      <Grid item xs={12} sm={1}>
        <Typography variant={isMobile ? "subtitle1" : "h6"}>
          <strong>Make</strong>
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"}>
          {car.Make}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={1}>
        <Typography variant={isMobile ? "subtitle1" : "h6"}>
          <strong>Model</strong>
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"}>
          {car.Model}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={1.5}>
        <Typography variant={isMobile ? "subtitle1" : "h6"}>
          <strong>Brand</strong>
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"}>
          {car.Brand}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={1}>
        <Typography variant={isMobile ? "subtitle1" : "h6"}>
          <strong>© Year</strong>
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"}>
          {car.CYear}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={1}>
        <Typography variant={isMobile ? "subtitle1" : "h6"}>
          <strong>Weight</strong>
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"}>
          {car["Weight (g)"]}g
        </Typography>
      </Grid>
      <Grid item xs={12} sm={1}>
        <Typography variant={isMobile ? "subtitle1" : "h6"}>
          <strong>Length</strong>
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"}>
          {car["Length (in)"]}in
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography variant={isMobile ? "subtitle1" : "h6"}>
          <strong>Series</strong>
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"}>
          {car.Series}
        </Typography>
      </Grid>
    </Grid>
  </CardContent>
</Card>
 
      <div
  style={{
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '20px',
    gridColumn: 'span 12', // Full width on larger screens
    gridRow: 'span 1', // Adjust as needed
    gap: isMobile ? '20px' : '0', // Space between boxes on mobile
  }}
>
  {[
    { title: totalWins, subtitle: "Total Wins", rank: winsRank },
    { title: `${roundedMedianTime}s`, subtitle: "Average Time" },
    { title: champWins, subtitle: "Championships", rank: champRank },
    { title: timesDownTrack, subtitle: "Trips Down the Track", rank: ordinalRank }
  ].map((stat, index) => (
    <Box
      key={index}
      backgroundColor={colors.greenAccent[400]}
      display="flex"
      alignItems="center"
      justifyContent="center"
      className="stat-box"
      style={{
        flex: 1,
        minWidth: isMobile ? '100%' : '150px', // Full width on mobile        minHeight: '100px', // Minimum height
        padding: '10px', // Padding inside the box
        margin: isMobile ? '10px 0' : '0 10px', // Margin outside the box
      }}
    >
      <StatBox {...stat} />
    </Box>
  ))}
</div>
      {/* Container for Histogram and TripsLine */}
      <div
        style={{
          gridColumn: "span 12",
          gridRow: "span 3",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Histogram {...densityPlotData} />
        <TripsLine {...linePlotData} />
      </div>
      <LastTenTable data={carLastTen2} gridColumn="span 6" />
    </div>
  );
};

export default CarTemplate;
  
