import React, { useState, useEffect } from 'react';
import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import * as d3 from 'd3';
import { useNavigate } from "react-router-dom";
import { tourneyData } from "../data/tourneys";

const cleanData = tourneyData.filter((d) => !isNaN(parseFloat(d.Time)));

const laneAverages = d3.group(cleanData, (d) => d.Lane);

// Calculate the average for each Lane
const laneAveragesArray = Array.from(laneAverages, ([Lane, data]) => ({
    Lane,
    AverageTime: d3.mean(data, (d) => parseFloat(d.Time) || 0), // Assuming "Time" is numeric
}));

// Reverse the order of the data to flip-flop the y-axis
// Sort the array by Lane in ascending order
laneAveragesArray.sort((a, b) => b.Lane - a.Lane);


const LanesChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

  window.addEventListener("resize", handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

  // Adjusted margins for mobile
  const mobileMargin = { top: 20, right: 30, bottom: 100, left: 30 };
  const desktopMargin = { top: 50, right: 130, bottom: 150, left: 60 };

  const displayData = isMobile ? [...laneAveragesArray].reverse() : laneAveragesArray;

  return (
    <ResponsiveBar
      data={displayData}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        tooltip: {
          container: {
            background: colors.primary[400],
            color: colors.greenAccent[100],
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      indexBy="Lane"
      keys={["AverageTime"]}
      groupMode="grouped"
      margin={isMobile ? mobileMargin : desktopMargin}
      layout={isMobile ? "vertical" : "horizontal"}
      padding={0.3}
      valueScale={{ type: "linear", min: 3, clamp: true }}
      indexScale={{ type: "band", round: true }}
      colors={(bar) => colors.greenAccent[600]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={isMobile
        ? {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Lane",
            legendPosition: "middle",
            legendOffset: 36, // Adjust as needed
          }
        : {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Average Time",
            legendPosition: "middle",
            legendOffset: 50,
          }}
          axisLeft={isMobile
            ? {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Average Time", // Corrected syntax
                legendPosition: "middle",
                legendOffset: -40, // Adjust as needed
              }
            : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: isDashboard ? undefined : "Lane",
                legendPosition: "middle",
                legendOffset: -40,
              }
          }
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      role="application"
      barAriaLabel={function (e) {
        return e.id + ": ggg " + e.formattedValue + " in country: " + e.indexValue;
      }}
      tooltip={(tooltip) => (
        <div
        style={{
          background: colors.primary[400],
          color: colors.greenAccent[100],
          padding: "8px", // Added padding
          position: "relative", // Added absolute positioning
          borderRadius: "4px", // Added border radius
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Added box shadow
        }}
      >          <strong>Lane {tooltip.indexValue}</strong>: {parseFloat(tooltip.value).toFixed(3)}
        </div>
      )}
    />
  );
};

export default LanesChart;




