import React, { useState, useEffect, useRef } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrophyImage from "./TrophyImage";
import * as d3 from "d3";
import { tourneyStatsData } from "../data/tourneystats";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const AccordionItem = ({ items }) => {
  const [expanded, setExpanded] = useState(false);
  const chartContainerRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hoveredData, setHoveredData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState(null);
  let tooltip = null;
  const navigate = useNavigate(); 

  const handleMouseOver = (event, d) => {
    setHoveredData(d);
    const xPosition = event.clientX;
    const yPosition = event.clientY;
    setTooltipPosition({ left: xPosition + 10, top: yPosition - 40 });
  };

  const handleMouseLeave = () => {
    setHoveredData(null);
    setTooltipPosition(null);
  };

  const handleMouseMove = (event) => {
    // Update the tooltip's position while the mouse moves over the bar
  };

  useEffect(() => {
    const isLandscape = () => window.innerWidth > window.innerHeight;

      const createBarChart = () => {
           // Make sure the ref is attached and the device is in landscape orientation
      if (!chartContainerRef.current || !isLandscape()) {
        return;
      }
           // Clear previous elements
           d3.select(chartContainerRef.current).selectAll("*").remove();
           
        const margin = { top: 20, right: 30, bottom: 40, left: 80 };
        const width = 400 - margin.left - margin.right;
        const height = 200 - margin.top - margin.bottom;
        const svg = d3
          .select(chartContainerRef.current)
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);

        const mostRecent = Math.max(...items.map((item) => item.tourneyid));
        const mostRecentMedTime = items.find((item) => item.tourneyid === mostRecent).MedTime;

        const allMedTimes = items.map((item) => item.MedTime);
        const allTournamentsMedian = d3.median(tourneyStatsData, (d) => d.MedTime);

        const maxTourneyID = Math.max(...items.map((item) => item.tourneyid));
        const last5Tournaments = tourneyStatsData.filter((item) => item.tourneyid > maxTourneyID - 4);
        const last5MedTimes = last5Tournaments.map((item) => item.MedTime);
        const last5TournamentsMedian = d3.median(last5MedTimes);

        const data = [
          { label: "Most Recent", value: mostRecentMedTime },
          { label: "All Tournaments", value: allTournamentsMedian },
          { label: "Last 5 Tournaments", value: last5TournamentsMedian },
        ];

        const x = d3.scaleLinear().domain([3.3, 4.3]).range([0, width]);
        const y = d3.scaleBand().domain(data.map((d) => d.label)).range([0, height]).padding(0.1);
        
        const colors2 = {
          "Most Recent": colors.greenAccent[500],
          "All Tournaments": colors.blueAccent[500],
          "Last 5 Tournaments": colors.redAccent[600],
        };

        const tooltip = d3
          .select(chartContainerRef.current)
          .append("div")
          .style("position", "absolute")
          .style("background", colors.primary[400])
          .style("padding", "5px")
          .style("border-radius", "3px")
          .style("pointer-events", "none")
          .style("opacity", 0);

        svg
          .selectAll(".bar")
          .data(data)
          .enter()
          .append("rect")
          .attr("class", "bar")
          .attr("y", (d) => y(d.label))
          .attr("x", 0)
          .attr("height", y.bandwidth())
          .attr("fill", (d) => colors2[d.label])
          .on("mouseenter", function (event, d) {
            d3.select(this).attr("class", "accordion-bar hovered");
            handleMouseOver(event, d);
            tooltip.transition().duration(200).style("opacity", 0.9);
            tooltip.html(`${d.label}<br><b>${d.value}s</b>`)
              .style("left", event.clientX - 10 + "px")
              .style("top", event.clientY + "px");
          })
          .on("mousemove", (event) => {
            tooltip.style("left", event.clientX + 10 + "px")
              .style("top", event.clientY - 40 + "px");
          })
          .on("mouseleave", function () {
            d3.select(this).attr("class", "accordion-bar");
            handleMouseLeave();
            tooltip.transition().duration(200).style("opacity", 0);
          })
          .transition()
          .duration(1000)
          .attr("width", (d) => x(d.value))
          .on("end", function () {
            svg
              .selectAll(".label")
              .data(data)
              .enter()
              .append("text")
              .attr("class", "label")
              .attr("y", (d) => y(d.label) + y.bandwidth() / 2)
              .attr("x", (d) => x(d.value) + 5)
              .attr("text-anchor", "start")
              .attr("fill", "white")
              .text((d) => d.value.toFixed(2))
              .transition()
              .duration(500)
              .style("opacity", 1);
          });
      };
      if (expanded) {
        createBarChart();
      } else {
        // Clear the SVG if the accordion is not expanded
        d3.select(chartContainerRef.current).selectAll("*").remove();
      }
  
      // Event listener for window resize
      const handleResize = () => {
        // Clear the SVG to ensure we have a clean slate
        d3.select(chartContainerRef.current).selectAll("*").remove();
  
        // Create the chart again if it's appropriate to do so
        if (expanded && isLandscape()) {
          createBarChart();
        }
      };
  
      // Add the event listener for resizing
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener and SVG when the component unmounts or before the effect runs again
      return () => {
        window.removeEventListener('resize', handleResize);
        d3.select(chartContainerRef.current).selectAll("*").remove();
      };
  
    }, [expanded, items, colors]); 

  // Helper function to calculate the median of an array
  const calculateMedian = (arr) => {
    const sortedArr = [...arr].sort((a, b) => a - b);
    const middle = Math.floor(sortedArr.length / 2);
    if (sortedArr.length % 2 === 0) {
      return (sortedArr[middle - 1] + sortedArr[middle]) / 2;
    } else {
      return sortedArr[middle];
    }
  };

  const handleClick = (carName) => {
    navigate(`/roster/${carName}`);
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={`accordion-summary ${expanded ? "" : "closed"}`}>
        <Typography>Tourney ID: {items[0].tourneyid}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "flex" }}>
        <div className="accordion-left">
        <div className="expanded-link" onClick={() => handleClick(items[0].Car)}>
            {expanded && <TrophyImage championName={items[0].Car} />}
          </div>
          <Typography>
            <strong>Median Tournament Time:</strong> {items[0].MedTime}
          </Typography>
          <Typography>
            <strong>Fastest Time:</strong> {items[0].FastestTime}
          </Typography>
          <Typography>
            <strong>Fastest Time Car:</strong>{" "}
            {items
              .filter((item) => item.FastestTime === items[0].FastestTime)
              .map((item) => (
                <div key={item.tourneyid} className="expanded-link" onClick={() => handleClick(item.FastestCar)}>
                {item.FastestCar}
              </div>
              ))}
          </Typography>
        </div>
        <div className="accordion-right" style={{ flex: 1 }} ref={chartContainerRef} />
      </AccordionDetails>
      {/* Tooltip element should be positioned absolutely relative to the body or a positioned container */}
    </Accordion>
  );
};

export default AccordionItem;








