import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../theme";

const StatBox = ({ title, subtitle, rank }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box 
      width="100%" 
      p={1} // Reduced padding for internal spacing
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.greenAccent[400],
        height: "100px", // Adjusted height for uniformity
        overflow: "hidden" // Prevent content overflow
      }}
    >
      <Typography
        variant={isMobile ? "h2" : "h2"} // Reduced font size
        fontWeight="bold"
        sx={{ color: colors.grey[700], mb: 0.5 }} // Reduced margin bottom for spacing
      >
        {title}
      </Typography>
      <Typography variant={isMobile ? "h5" : "h6"} sx={{ color: colors.greenAccent[900], textAlign: "center", mb: 1 }}>
        {subtitle}
      </Typography>
      {rank !== "N/A" && rank !== undefined && (
        <Typography
          variant="h7"
          fontStyle="italic"
          sx={{
            color: colors.greenAccent[600],
            position: "absolute",
            bottom: "5px", // Adjusted position to avoid overlap
            left: "50%",
            transform: "translateX(-50%)", // Center align
            maxWidth: "90%", // Ensures text doesn't touch the edges
            textAlign: "center"
          }}
        >
          {rank} all-time
        </Typography>
      )}
    </Box>
  );
};

export default StatBox;




