import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs = () => {
  const form = useRef();
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_49n4056', 'template_je4txls', form.current, 'gZXZXMeH7iM1vSIOc')
      .then((result) => {
          console.log(result.text);
          setMessage('Message sent');
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="contact-form">
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" required />
      <label>Email</label>
      <input type="email" name="user_email" required />
      <label>Message</label>
      <textarea name="message" rows ="10" required/>
      <input type="submit" value="Send" />
    </form>
    {message && <div className="notification">{message}</div>}
    </div>
  );
};

export default ContactUs;