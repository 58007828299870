import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { ResponsiveBoxPlot } from "@nivo/boxplot";
import { tokens } from "../theme";
import * as d3 from 'd3';
import { useNavigate } from "react-router-dom";
import { tourneyData } from "../data/tourneys";

const cleanData = tourneyData.filter((d) => !isNaN(parseFloat(d.Time)));

const laneAverages = d3.group(cleanData, (d) => d.Lane);

// Initialize an array to store flattened data
const flattenedData = [];

// Iterate through the laneAverages
laneAverages.forEach((values, Lane) => {
    const valuesArray = values.map((d) => parseFloat(d.Time) || 0).sort((a, b) => a - b);
    const mu = d3.mean(valuesArray);
    const sd = d3.deviation(valuesArray);
    const n = valuesArray.length;
    const min = d3.min(valuesArray);
    const max = d3.max(valuesArray);
  
    // Create multiple entries for each group with constant group-level data
    valuesArray.forEach((value) => {
      flattenedData.push({
        group: `Lane ${Lane}`,
        mu,
        sd,
        n,
        min,
        max,
        value,
      });
    });
  });

// Sort the flattenedData array by the "Lane" property
flattenedData.sort((a, b) => {
    const laneA = parseInt(a.group.match(/\d+/)[0]); // Extract the lane number from the group
    const laneB = parseInt(b.group.match(/\d+/)[0]);
  
    return laneA - laneB;
  });

const LanesBoxPlot = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

    useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const mobileMargin = { top: 30, right: 30, bottom: 50, left: 30 };
  const desktopMargin = { top: 60, right: 140, bottom: 60, left: 60 };

    return (
        <div style = {{ height: '500px'}}><h2 style = {{ marginBottom: isMobile ? "0px" : 
        "-40px"}}>Time Distributions by Lane in Tournament Racing</h2>
        <ResponsiveBoxPlot
        data={flattenedData}
        theme={{
            // added
            axis: {
              domain: {
                line: {
                  stroke: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[100],
                },
              },
            },
            tooltip: {
                container: {
                  background: colors.primary[400], // Change 'your-color-here' to your desired background color
                  color: colors.greenAccent[100], // Change 'white' to your desired text color
                },
              },
            legends: {
              text: {
                fill: colors.grey[100],
              },
            },
          }}
        margin={isMobile ? mobileMargin : desktopMargin}        minValue={3.4}
        maxValue={4.6}
        padding={0.12}
        enableGridX={false}
        axisBottom={{
          tickSize: isMobile ? 3 : 5,
          tickPadding: isMobile ? 3 : 5,
          tickRotation: isMobile ? -45 : 0,
          legendPosition: 'middle',
          legendOffset: isMobile ? 20 : 32
      }}
      axisLeft={{
        tickSize: isMobile ? 3 : 5,
        tickPadding: isMobile ? 3 : 5,
        tickRotation: 0,
        legend: 'Time (s)',
        legendPosition: 'middle',
        legendOffset: isMobile ? -30 : -40
    }}
        colors={(bar) => colors.greenAccent[600]}
        borderRadius={2}
        borderWidth={2}
        medianWidth={3}
        medianColor={colors.blueAccent[500]}
        whiskerEndSize={0.6}
        motionConfig="gentle"
    />
    </div>
)
  };
  
  export default LanesBoxPlot;