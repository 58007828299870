import React, { useEffect } from 'react';
import { useTheme, useMediaQuery } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import { WinsData as data } from "../data/totalwins";
import { useNavigate } from "react-router-dom";

const TotalWinsBarChart = ({ isDashboard = false, minWins }) => {
  const theme = useTheme();
  const navigate = useNavigate(); 
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Sort the data array by descending "Wins" values
  data.sort((a, b) => b.Wins - a.Wins);

  // Filter the data array to include only cars with more than the specified minimum number of wins
  const filteredData = data.filter((Car) => Car.Wins >= minWins);

  useEffect(() => {
    console.log('TotalWinsBarChart component has mounted');
    console.log('Data available:', filteredData);
  }, [filteredData]);

  // Define responsive margins
  const chartMargins = isMobile
    ? { top: 20, right: 20, bottom: 150, left: 30 } // Adjusted for mobile
    : { top: 50, right: 130, bottom: 150, left: 60 };

  // Define responsive axisBottom
  const axisBottom = isMobile
    ? {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 90, // Rotate ticks to make them vertical on mobile
        legendOffset: 0,
        legend: ''
      }
    : {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -90,
        legendOffset: 90,
        legend: 'Total Wins'
      };

  // Define responsive axisLeft
  const axisLeft = isMobile
    ? { tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendPosition: 'middle', legendOffset: -40 }
    : { tickSize: 5, tickPadding: 5, tickRotation: 0, legend: isDashboard ? undefined : "Total Wins", legendPosition: "middle", legendOffset: -40 };

  return (
    <ResponsiveBar
      data={filteredData}
      keys={["Wins"]}
      indexBy="Car"
      margin={chartMargins}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={(bar) => colors.greenAccent[600]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={axisBottom}
      axisLeft={axisLeft}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        tooltip: {
          container: {
            background: colors.primary[400],
            color: colors.greenAccent[100],
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      onClick={(bar, event) => {
        const carData = bar.data;
        if (carData && carData.Car) {
          navigate(`/roster/${carData.Car}`);
        } else {
          console.error('Car property is undefined in carData:', carData);
        }
      }}
    />
  );
};

export default TotalWinsBarChart;
