import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';
import { Link } from 'react-router-dom'; // Import the Link component

const TenTourneys = ({ tenTourneyData }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  const last10Entries = tenTourneyData.slice(-10).reverse();

  const columns = [
    { field: 'TourneyID', headerName: 'Tourney ID', width: 150 },
    {
        field: "car",
        headerName: "Champion",
        flex: 1,
        cellClassName: "name-column--cell",
        renderCell: (params) => (
          <Link
          to={`/roster/${params.value}`}
          style={{
            textDecoration: "none", // Remove underline
            color: "inherit", // Inherit text color from the parent
            // Add other styling as needed
          }}
        >
          {params.value}
        </Link>
        ),
      }  ];

  const rows = last10Entries.map((entry, index) => ({
    id: index + 1, // Create a unique ID for each row
    car: entry.Car,
    TourneyID: entry.TourneyID,
  }));

  return (
    <div style={{ height: 400, marginTop: "30px" }} sx={{ width: { xs: '100%', md: '33%' } }}>
     <h2>Last 10 Champions</h2>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
              display: "none"
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              width: "0px",
            },
          }}
      />
    </div>
  );
};

export default TenTourneys;
