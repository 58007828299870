/* global gtag */ // Add this line to declare gtag as a global variable

import { useState, useEffect } from "react";
import Topbar from "./scenes/global/Topbar";
import CustomProSidebar from "./scenes/global/Sidebar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Routes, Route, useLocation } from "react-router-dom"; // Remove useParams import
import Dashboard from "./scenes/dashboard";
import Roster from "./scenes/roster";
import WinsBar from "./scenes/wins";
import { RosterData } from "./data/roster";
import CarTemplate from "./components/CarTemplate";
import TournamentsPage from "./scenes/tournaments";
import CarComparisonPage from "./components/Head2Head";
import LanesPage from "./scenes/lanes";
import Rankings from "./scenes/rankings";
import FAQ from "./scenes/faq";
import ContactScene from "./scenes/contact";


function loadGoogleAnalytics() {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-FCL5S927NK";
  script.async = true;
  document.body.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function () { // Define gtag on the window object
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "G-FCL5S927NK");
}

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    if (!window.gtag) {
      loadGoogleAnalytics();
    }

    window.gtag && window.gtag("config", "G-FCL5S927NK", {
      page_path: location.pathname + location.search,
    });
  }, [location]);
}


function App() {
  usePageTracking(); 
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <CustomProSidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/roster" element={<Roster />} />
              <Route path="/wins" element={<WinsBar />} />
              <Route path="/tournaments" element={<TournamentsPage />} />
              <Route path="/lanes" element={<LanesPage />} />
              <Route path="/h2h" element={<CarComparisonPage />} />
              <Route path="/rankings" element={<Rankings />} />
              <Route path="/rankings" element={<Rankings />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/contact" element={<ContactScene />} />
              <Route path="/roster/:carName" element={<CarTemplate />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
