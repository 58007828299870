import React, { useState } from 'react';
import { useTheme, Slider, Box, Typography, useMediaQuery } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { tokens } from '../theme';
import { useNavigate } from 'react-router-dom';
import * as d3 from 'd3';
import { tourneyData } from '../data/tourneys';

const TotalTripsBarChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [minCount, setMinCount] = useState(50);

  const handleMinCountChange = (event, newValue) => {
    setMinCount(newValue);
  };

  const numericTimeCountsByCar = d3.group(tourneyData, (d) => d.Car);
  const timeCountsResult = Array.from(numericTimeCountsByCar, ([car, data]) => ({
    Car: car,
    Count: d3.sum(data, (d) => !isNaN(parseFloat(d.Time)) && d.Time !== 'DNF' && d.Time !== 'CE'),
  }));

  const filteredData = timeCountsResult.filter((entry) => entry.Count >= minCount);
  const sortedData = filteredData.slice().sort((a, b) => b.Count - a.Count);

  const barChartMargins = isSmallScreen
    ? { top: 20, right: 20, bottom: 60, left: 40 }
    : { top: 50, right: 130, bottom: 150, left: 60 };

    const displayXAxisLabels = !isSmallScreen && sortedData.length <= 50

    const axisBottom = {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -90,
      legendPosition: 'middle',
      legendOffset: isSmallScreen ? 32 : 36,
      tickValues: displayXAxisLabels ? undefined : [], // Hide labels if too crowded
    };
  

  const axisLeft = isSmallScreen
  ? {
      tickSize: 0, // No tick marks
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Runs',
      legendPosition: 'middle',
      legendOffset: -20,
      tickValues: [], // No tick labels
    }
  : {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: isDashboard ? undefined : 'Runs',
      legendPosition: 'middle',
      legendOffset: -50,
      // Keep the existing tickValues or other settings for larger screens
    };

  return (
    <Box sx={{ height: isSmallScreen ? '300px' : '500px', width: '100%', margin: '0 auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
        <Typography variant="h2" component="h2">
          All-Time Track Runs
        </Typography>
        <Typography variant="h4" component="h4">
          Minimum Threshold: {minCount}
        </Typography>
        <Slider
          value={minCount}
          onChange={handleMinCountChange}
          min={0}
          max={100}
          step={1}
          valueLabelDisplay="auto"
          color="secondary"
        />
      </Box>
      <ResponsiveBar
        data={sortedData}
        keys={['Count']}
        indexBy="Car"
        margin={barChartMargins}
        padding={0.3}
        colors={(bar) => colors.greenAccent[600]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        onClick={(bar, event) => {
          navigate(`/roster/${bar.data.Car}`);
        }}
        // Add additional properties as needed for responsiveness
        theme={{
          axis: {
            domain: {
              line: {
                stroke: colors.grey[100],
              },
            },
            legend: {
              text: {
                fill: colors.grey[100],
                fontSize: isSmallScreen ? '14px' : '16px', // conditional fontSize from the second theme
              },
            },
               ticks: {
      line: {
        stroke: colors.grey[100],
        strokeWidth: 1,
      },
         text: {
        fill: colors.grey[100],
        fontSize: isSmallScreen ? '11px' : '13px', // conditional fontSize from the second theme
      },
    },
  },
  tooltip: {
    container: {
      background: colors.primary[400],
      color: colors.greenAccent[100],
    },
  },
  legends: {
    text: {
      fill: colors.grey[100],
    },
  },
          // Extend theme as needed
        }}
        // Extend with other nivo/bar properties as needed
      />
    </Box>
  );
};

export default TotalTripsBarChart;

