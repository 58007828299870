import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";

const MARGIN = { top: 30, right: 30, bottom: 50, left: 50 };

const TripsLine = ({ width, height, data }) => {
  const svgRef = useRef();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hoveredData, setHoveredData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState(null);

  const mergedDataRef = useRef([]); // Use a ref to store mergedData

  const handleMouseOver = (event, d) => {
    setHoveredData(d);

    const xPosition = event.clientX;
    const yPosition = event.clientY;

    setTooltipPosition({ left: xPosition + 10, top: yPosition - 40 });
  };

  const handleMouseLeave = () => {
    setHoveredData(null);
    setTooltipPosition(null);
  };

  const xScale = d3
    .scaleLinear()
    .domain([1, data.length])
    .range([MARGIN.left, width - MARGIN.right]);

  const findNearestDataPoint = (xPosition, xScale) => {
    const bisect = d3.bisector((d) => d.tripID).left;
    const tripID = xScale.invert(xPosition);

    const index = bisect(mergedDataRef.current, tripID, 1);

    if (index === 0) {
      return mergedDataRef.current[0];
    }
    if (index === mergedDataRef.current.length) {
      return mergedDataRef.current[mergedDataRef.current.length - 1];
    }

    const d0 = mergedDataRef.current[index - 1];
    const d1 = mergedDataRef.current[index];
    return Math.abs(d0.tripID - tripID) < Math.abs(d1.tripID - tripID) ? d0 : d1;
  };

  const handleMouseMove = (event) => {
    if (tooltipPosition) {
      const svgElement = svgRef.current;
      const svgRect = svgElement.getBoundingClientRect();

      const xPosition = event.clientX - svgRect.left;
      const yPosition = event.clientY - svgRect.top;

      setTooltipPosition((prevPosition) => ({
        left: xPosition + svgRect.left + 10,
        top: yPosition + svgRect.top - 40,
        transition: "none",
      }));

      const nearestDataPoint = findNearestDataPoint(xPosition, xScale);
      setHoveredData(nearestDataPoint);
    }
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current).attr("width", width).attr("height", height);
 // Clear previous elements
 svg.selectAll("*").remove();

    const xScale = d3
      .scaleLinear()
      .domain([1, data.length])
      .range([MARGIN.left, width - MARGIN.right]);

    const yScale = d3
      .scaleLinear()
      .domain([d3.min(data, (d) => d.time) - 0.2, d3.max(data, (d) => d.time) + 0.2])
      .range([height - MARGIN.bottom, MARGIN.top]);

    const median = d3.median(data, (d) => d.time);

    const line = d3
      .line()
      .x((d, i) => xScale(i + 1))
      .y((d) => yScale(d.time));

    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", colors.primary[400])
      .attr("stroke-width", 2)
      .attr("d", line)
      .on("mouseover", (event, d) => handleMouseOver(event, d))
      .on("mousemove", handleMouseMove)
      .on("mouseleave", handleMouseLeave);

    const medianLine = d3
      .line()
      .x((_, i) => xScale(i + 1))
      .y(yScale(median));

    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "5 5")
      .attr("d", medianLine);

    const movingAverage = (values, period) => {
      return values.map((d, i) => {
        if (i < period - 1) {
          return null;
        }
        return d3.mean(values.slice(i - period + 1, i + 1));
      });
    };

    const period5 = 5;
    const period10 = 10;

    const movingAverageData5 = movingAverage(data.map((d) => d.time), period5).map((d, i) => ({
      time: d || 0,
      tripID: i + 1,
    }));

    const movingAverageData10 = movingAverage(data.map((d) => d.time), period10).map((d, i) => ({
      time: d || 0,
      tripID: i + 1,
    }));

    const mergedData = data.map((d, i) => ({
      tripID: i + 1,
      time: d.time,
      ma5: movingAverageData5[i].time,
      ma10: movingAverageData10[i].time,
      median,
    }));

    mergedDataRef.current = mergedData; // Update the ref with mergedData

    // Add the code for rendering moving average lines and axes here
    // Make sure to use mergedData for rendering
    // ...

    const movingAverageLine5 = d3
    .line()
    .defined((d) => d.ma5 > 0)
    .x((d) => xScale(d.tripID))
    .y((d) => yScale(d.ma5))
    .curve(d3.curveBasis);

    svg
    .append("path")
    .datum(mergedData)
    .attr("fill", "none")
    .attr("stroke", colors.greenAccent[400])
    .attr("stroke-width", 2)
    .attr("d", movingAverageLine5);

    const movingAverageLine10 = d3
    .line()
    .defined((d) => d.ma10 > 0)
    .x((d) => xScale(d.tripID))
    .y((d) => yScale(d.ma10))
    .curve(d3.curveBasis);

  svg
    .append("path")
    .datum(mergedData)
    .attr("fill", "none")
    .attr("stroke", colors.blueAccent[700])
    .attr("stroke-width", 2)
    .attr("d", movingAverageLine10);

      // Add code to render axes here
  svg
  .append("g")
  .attr("transform", `translate(0, ${height - MARGIN.bottom})`)
  .call(d3.axisBottom(xScale).ticks(5));

svg.append("g").attr("transform", `translate(${MARGIN.left}, 0)`).call(d3.axisLeft(yScale));

  }, [data, height, width, colors.primary, mergedDataRef]); // Include mergedDataRef as a dependency

  const tooltip = hoveredData && tooltipPosition && (
    <div
      className="tooltip"
      style={{
        left: tooltipPosition.left,
        top: tooltipPosition.top,
        background: colors.primary[400],
        color: "#fff",
        padding: "8px",
        position: "absolute",
        borderRadius: "4px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
        pointerEvents: "none",
        zIndex: 999,
      }}
    >
      <div><strong>Time:</strong> {hoveredData.time !== undefined ? hoveredData.time.toFixed(2) : ""}</div>
      <div><strong>Trip ID:</strong> {hoveredData.tripID !== undefined ? hoveredData.tripID : ""}</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "12px", height: "12px", marginRight: "5px", backgroundColor: colors.greenAccent[400] }}></div>
        <strong>Moving Average (5): </strong> &nbsp; {hoveredData.ma5 !== undefined ? hoveredData.ma5.toFixed(2) : ""}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "12px", height: "12px", marginRight: "5px", backgroundColor: colors.blueAccent[700] }}></div>
        <strong>Moving Average (10): </strong> &nbsp; {hoveredData.ma10 !== undefined ? hoveredData.ma10.toFixed(2) : ""}
      </div>
    </div>
  );

  return (
    <div className="trips-line-container">
      <h2>Car performance over time</h2>
      <svg
        width={width}
        height={height}
        ref={svgRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      ></svg>
      {tooltip}
    </div>
  );
};

export default TripsLine;
