import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ContactUs from '../../components/ContactForm2';
import Header from '../../components/Header';

const ContactScene = () => {
  useEffect(() => {
    // Target the first element with the 'content' class
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      contentElement.scrollTop = 0;
    }
  }, []);
  return (
    <Box m="20px" paddingBottom={200}>
         <Header title="Contact Us" subtitle="We love hearing from fellow racers and enthusiasts. Use the form below to drop us a line and we'll be in touch soon."/>
      <ContactUs />
      </Box>
  );
};

export default ContactScene;