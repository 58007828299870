import React, { useState, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TrafficIcon from '@mui/icons-material/Traffic';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import EmailIcon from '@mui/icons-material/Email';

const Item = ({ title, to, icon, selected, setSelected, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: adds an animation
    });
  };

  return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => {
          setSelected(title);
          scrollToTop(); // Call the scrollToTop function here
          setIsCollapsed(true);
        }}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    );
};

const CustomProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState('Dashboard');

  // Define the breakpoint for mobile devices
  const mobileBreakpoint = 768;

  useEffect(() => {
    // Function to handle resize events
    const handleResize = () => {
      // Check if the window width is less than the breakpoint
      setIsCollapsed(window.innerWidth <= mobileBreakpoint);
    };

    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      sx={{
        '& .pro-sidebar-inner': {
          background: `${colors.primary[400]} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: '#868dfb !important',
        },
        '& .pro-menu-item.active': {
          color: '#6870fa !important',
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={<MenuOutlinedIcon />}
            style={{
              margin: '10px 0 20px 0',
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  Welcome
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../radcams.png`}
                  style={{ cursor: 'pointer', borderRadius: '50%' }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: '10px 0 0 0' }}
                >
                  Diecast Database
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  Race League & Performance Analytics
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setIsCollapsed={setIsCollapsed} 
            />
            <Item
              title="Roster"
              to="/roster"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setIsCollapsed={setIsCollapsed} 
            />
            <Item
              title="All-Time Wins"
              to="/wins"
              icon={<SportsScoreIcon />}
              selected={selected}
              setSelected={setSelected}
              setIsCollapsed={setIsCollapsed} 
            />
            <Item
              title="Tournaments"
              to="/tournaments"
              icon={<EmojiEventsIcon />}
              selected={selected}
              setSelected={setSelected}
              setIsCollapsed={setIsCollapsed} 
            />
            <Item
              title="Head to Head"
              to="/h2h"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setIsCollapsed={setIsCollapsed} 
            />
            <Item
              title="Lane Analytics"
              to="/lanes"
              icon={<TrafficIcon />}
              selected={selected}
              setSelected={setSelected}
              setIsCollapsed={setIsCollapsed} 
            />
            <Item
              title="Rankings"
              to="/rankings"
              icon={<MobiledataOffIcon />}
              selected={selected}
              setSelected={setSelected}
              setIsCollapsed={setIsCollapsed} 
            />
            <Item
              title="FAQ"
              to="/faq"
              icon={<QuestionMarkIcon />}
              selected={selected}
              setSelected={setSelected}
              setIsCollapsed={setIsCollapsed} 
            />
            <Item
              title="Contact"
              to="/contact"
              icon={<EmailIcon />}
              selected={selected}
              setSelected={setSelected}
              setIsCollapsed={setIsCollapsed} 
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default CustomProSidebar;
