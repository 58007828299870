import React, { useState, useEffect } from "react";
import AccordionItem from "../../components/TourneyAccordion";
import { tourneyStatsData } from "../../data/tourneystats";
import Header from "../../components/Header";
import TotalTourneysBarChart from "../../components/TourneyBar";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

const TournamentsPage = () => {
  useEffect(() => {
    // Target the first element with the 'content' class
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      contentElement.scrollTop = 0;
    }
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const groupedData = groupDataByTourneyId(tourneyStatsData);
  groupedData.sort((a, b) => b[0].tourneyid - a[0].tourneyid);

  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const visibleData = groupedData.slice(startIdx, endIdx);
  const totalPages = Math.ceil(groupedData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="page-container" style={{ height: '500px', marginLeft: '25px' }}>
<Header title="Tournament Details and Champions" subtitle="Tournaments are held between the 72 fastest cars on the roster. On this page, see the cumulative championship totals for champion cars, and click into tournaments to see additional details." />
      <TotalTourneysBarChart />      <div className="accordion-container">
        {visibleData.map((group, index) => (
          <AccordionItem key={index} items={group} />
        ))}
      </div>
      <div className="pagination" style={{ marginTop: '10px', marginRight: '30px', textAlign: "right"}}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            backgroundColor: colors.greenAccent[500], // Change the background color to blue
            color: "#fff",             // Change the text color to white
            border: "none",            // Remove the border
            borderRadius: "4px",       // Add some border radius for rounded corners
            cursor: "pointer",        // Change cursor on hover
          }}
        >
          Previous
        </button>
        <span>    Page {currentPage} of {totalPages}    </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          style={{
            backgroundColor: colors.greenAccent[500], // Change the background color to blue
            color: "#fff",             // Change the text color to white
            border: "none",            // Remove the border
            borderRadius: "4px",       // Add some border radius for rounded corners
            cursor: "pointer",        // Change cursor on hover
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const groupDataByTourneyId = (data) => {
  const groupedData = {};
  data.forEach((item) => {
    if (!groupedData[item.tourneyid]) {
      groupedData[item.tourneyid] = [];
    }
    groupedData[item.tourneyid].push(item);
  });
  return Object.values(groupedData);
};

export default TournamentsPage;


