import React, { useState, useEffect } from 'react';
import {
  FormControl,
  TextField,
  Box,
  Typography,
  Paper,
  Grid,
  Slider,
  useMediaQuery,
  useTheme,
  Button
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { tourneyData } from '../data/tourneys';
import Header from './Header';

function CarComparisonPage() {
  const [car1, setCar1] = useState('');
  const [car2, setCar2] = useState('');
  const [carList, setCarList] = useState([]);
  const [results, setResults] = useState([]);
  const [hovered, setHovered] = useState(null);
  const [numRecords, setNumRecords] = useState(10); // Number of records to display, default is 10

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const uniqueCars = [...new Set(tourneyData.map((entry) => entry.Car))];
    setCarList(uniqueCars);
  }, []);

    useEffect(() => {
    // Update numRecords to results.length if it's less than 10
    if (results.length < 10) {
      setNumRecords(results.length);
    }
  }, [results]);

  useEffect(() => {
    // Update numRecords to results.length if it's less than 10
    if (results.length > 9) {
      setNumRecords(10);
    }
  }, [results]);

  const compareCars = (selectedCar1, selectedCar2) => {
    setCar1(selectedCar1);
    setCar2(selectedCar2);

    if (selectedCar1 && selectedCar2) {
      const matchingEntries = tourneyData.filter((entry) => {
        const matchingCar1 = entry.Car === selectedCar1;
        const matchingCar2 = entry.Car === selectedCar2;
        return matchingCar1 || matchingCar2;
      });

      const groupedResults = {};

      matchingEntries.forEach((entry) => {
        const { tourneyid, Group, Car, Time } = entry;

        if (!groupedResults[tourneyid]) {
          groupedResults[tourneyid] = {};
        }

        if (!groupedResults[tourneyid][Group]) {
          groupedResults[tourneyid][Group] = {
            [selectedCar1]: null,
            [selectedCar2]: null,
          };
        }

        if (Car === selectedCar1) {
          groupedResults[tourneyid][Group][selectedCar1] = parseFloat(Time); // Parse Time as a float
        } else if (Car === selectedCar2) {
          groupedResults[tourneyid][Group][selectedCar2] = parseFloat(Time); // Parse Time as a float
        }
      });

      const filteredResults = [];

      for (const tourneyid in groupedResults) {
        for (const Group in groupedResults[tourneyid]) {
          const entry = groupedResults[tourneyid][Group];
          if (entry[selectedCar1] !== null && entry[selectedCar2] !== null) {
            filteredResults.push({
              tourneyid: Number(tourneyid),
              Group: Number(Group),
              Date: tourneyData.find((entry) => entry.tourneyid === Number(tourneyid)).Date, // Add Date
              [selectedCar1]: entry[selectedCar1],
              [selectedCar2]: entry[selectedCar2],
            });
          }
        }
      }

      // Sort results in reverse order based on tourneyid (most recent first)
      filteredResults.sort((a, b) => b.tourneyid - a.tourneyid);

      setResults(filteredResults);
    }
  };

  // Function to calculate the count of instances where car1's time is lower than car2's time
  const countInstancesWithLowerTime = (selectedCar1, selectedCar2) => {
    if (selectedCar1 && selectedCar2) {
      const count1 = results.reduce((acc, result) => {
        const car1Time = parseFloat(result[selectedCar1]);
        const car2Time = parseFloat(result[selectedCar2]);

        if (!isNaN(car1Time) && (!isNaN(car2Time) || isNaN(car2Time))) {
          if (isNaN(car2Time) || car1Time < car2Time) {
            return acc + 1;
          }
        }
        return acc;
      }, 0);

      const count2 = results.reduce((acc, result) => {
        const car1Time = parseFloat(result[selectedCar1]);
        const car2Time = parseFloat(result[selectedCar2]);

        if (!isNaN(car2Time) && (!isNaN(car1Time) || isNaN(car1Time))) {
          if (isNaN(car1Time) || car2Time < car1Time) {
            return acc + 1;
          }
        }
        return acc;
      }, 0);

      return [count1, count2];
    }
    return [0, 0];
  };

  const [countCar1, countCar2] = countInstancesWithLowerTime(car1, car2);

  const maxCircleWidth = Math.max(
    ...results.slice(0, numRecords).map((result) => {
      const car1Width = result[car1] ? result[car1].toString().length * 8 : 0; // Check if result[car1] is defined
      const car2Width = result[car2] ? result[car2].toString().length * 8 : 0; // Check if result[car2] is defined
      return Math.max(car1Width, car2Width);
    })
  );
  
  return (
    <div>
      <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: { xs: 'column', sm: 'row' },
      gap: { xs: '10px', sm: '0px' },
      marginBottom: '20px',
      marginLeft: '25px' // Add margin at the bottom for small devices
    }}>
      <Header
        title="Car Comparison Page"
        subtitle="Select two cars to see how they have performed when on the track together."
      />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" padding={isMobile ? 2 : 4}>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={5} lg={4}>            {/* Car 1 Selection */}
            <Autocomplete
              options={carList}
              getOptionLabel={(option) => option}
              value={car1}
              onChange={(event, newValue) => {
                compareCars(newValue, car2);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Car 1"
                />
              )}
            />
            {/* Wins for Car 1 */}
            <Paper
              elevation={3}
              sx={{
                margin: '10px',
                width: '100px',
                height: '100px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                backgroundColor: countCar1 > countCar2 ? 'success.main' : 'error.main',
              }}
            >
              {countCar1}
              <Typography variant="subtitle1">Wins</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={2} container justifyContent="center" alignItems="center">
            {/* "VS" Typography */}
            <Typography variant="h2" component="div">
              vs
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>            {/* Car 2 Selection */}
            <Autocomplete
              options={carList}
              getOptionLabel={(option) => option}
              value={car2}
              onChange={(event, newValue) => {
                compareCars(car1, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Car 2"
                />
              )}
            />
            {/* Wins for Car 2 */}
            <Paper
              elevation={3}
              sx={{
                margin: '10px',
                width: '100px',
                height: '100px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                backgroundColor: countCar2 > countCar1 ? 'success.main' : 'error.main',
              }}
            >
              {countCar2}
              <Typography variant="subtitle1">Wins</Typography>
            </Paper>
          </Grid>
        </Grid>
        {/* Slider for Number of Records */}
        <Box marginTop={isMobile ? 2 : 6} width="100%">
          <Box>
        <Typography variant="gutterBottom">Number of Records to Display: {numRecords} out of {results.length}</Typography>
        </Box>
        <Slider
          value={numRecords}
          onChange={(event, newValue) => setNumRecords(newValue)}
          aria-labelledby="num-records-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={1}
          max={results.length || 1}
          sx={{ width: '80%', maxWidth: '400px' }}
          color="secondary"
        />
        </Box>
        {/* Results Table */}
        <Box width="100%" overflow="auto" marginTop={2} justifyContent = "center">
        <table style={{ width: '75%', maxWidth: '1000px', margin: '0 auto' }}>
        <thead>
  <tr>
    <th style={{ textAlign: 'left', paddingRight: '4px' }}>Date</th>
    { !isMobile && <th style={{ textAlign: 'left', paddingRight: '4px' }}>Tourney ID</th> }
    { !isMobile && <th style={{ textAlign: 'left', paddingRight: '4px' }}>Group</th> }
    <th style={{ textAlign: 'left', paddingRight: '24px' }}> <a href={`/roster/${car1}`} className="expanded-link">{car1}</a></th>
    <th style={{ textAlign: 'left' }}> <a href={`/roster/${car1}`} className="expanded-link">{car2}</a></th>
  </tr>
</thead>
<tbody>
  {results.slice(0, numRecords).map((result, index) => (
    <tr key={index}>
      <td style={{ paddingRight: '4px' }}>{result.Date}</td>
      { !isMobile && <td style={{ paddingRight: '4px' }}>{result.tourneyid}</td> }
      { !isMobile && <td style={{ paddingRight: '4px' }}>{result.Group}</td> }
      <td style={{ position: 'relative', paddingRight: '24px', width: `${maxCircleWidth + 20}px` }}>
        {isNaN(result[car1]) ? (
          result[car1]
        ) : (
          <>
            {result[car1]}
            {isNaN(result[car2]) || result[car1] < result[car2] ? (
              <div
                style={{
                  width: '6px',
                  height: '6px',
                  backgroundColor: 'green',
                  borderRadius: '50%',
                  position: 'absolute',
                  left: `${result[car1].toString().length * 8 + 5}px`,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              />
            ) : null}
          </>
        )}
      </td>
      <td style={{ position: 'relative', width: `${maxCircleWidth + 20}px` }}>
        {isNaN(result[car2]) ? (
          result[car2]
        ) : (
          <>
            {result[car2]}
            {isNaN(result[car1]) || result[car2] < result[car1] ? (
              <div
                style={{
                  width: '6px',
                  height: '6px',
                  backgroundColor: 'green',
                  borderRadius: '50%',
                  position: 'absolute',
                  left: `${result[car2].toString().length * 8 + 5}px`,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              />
            ) : null}
          </>
        )}
      </td>
    </tr>
  ))}
</tbody>
          </table>
        </Box>
      </Box>
    </div>
  );
}

export default CarComparisonPage;















