import React, { useState, useEffect } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { tourneyData } from "../../data/tourneys";
import notify from "../../components/Notification";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [searchInput, setSearchInput] = useState("");
  const [zeroValuesInfo, setZeroValuesInfo] = useState([]);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const toggleMessageBox = () => {
    setShowMessageBox(!showMessageBox);
  };

  const MessageBox = ({ message, onClose }) => {
    return (
      <div
        style={{
          position: 'fixed',
          top: '52px', // Adjust the vertical position as needed
          right: '66px',
          background: colors.primary[500], 
          padding: '16px',
          border: '1px solid #ccc',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          zIndex: '9999',
          width: '240px', // Adjust the width as needed
          borderRadius: '8px', // Add rounded corners
        }}
      >
        <div>{message}</div>
        <button
          style={{
            background: colors.primary[900],
            color: colors.greenAccent[400],
            padding: '8px 12px',
            borderRadius: '4px',
            marginTop: '10px',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={onClose}
        >
          Close
        </button>
      </div>
    );
  };

  const toggleLoginForm = () => {
    setShowLoginForm(prevState => !prevState);
};

  
  useEffect(() => {
    // Summarize tourneyData
    const summarizedData = tourneyData.reduce((acc, curr) => {
      const key = `${curr.tourneyid}-${curr.Group}`;

      if (!acc[key]) {
        acc[key] = {
          tourneyid: curr.tourneyid,
          Group: curr.Group,
          TotalWins: 0,
        };
      }

      acc[key].TotalWins += curr.Advance;

      return acc;
    }, {});

    // Convert the summarized data object back to an array
    const summarizedDataArray = Object.values(summarizedData);

    // Check for 0 values in the summarized data
    const zeroValues = summarizedDataArray.filter((item) => item.TotalWins === 0);

    // Set zeroValuesInfo with the details of 0 values
    setZeroValuesInfo(zeroValues);
  }, []);

  useEffect(() => {
    // Show a notification if zero values are found
    if (zeroValuesInfo.length > 0) {
      const notificationMessage = `Zero values found in the following tourneyid and group combinations:\n${zeroValuesInfo.map(
        (item) => `Tourney ID: ${item.tourneyid}, Group: ${item.Group}`
      ).join("\n")}`;

      // Log the notification message to the console
      console.log("Notification Message:", notificationMessage);

      notify(notificationMessage);

          // Pass the notification message to the state
    setNotificationMessage(notificationMessage);
    }
  }, [zeroValuesInfo]);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchClick = () => {
    // Simulate a search operation (replace with your actual search logic)
    alert(`Searching for: ${searchInput}`);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    const username = event.target[0].value;
    const password = event.target[1].value;
    
    // Hardcoded check for demonstration purposes only
    if (username === 'admin' && password === 'password123') {
        setIsAdmin(true);
        setShowLoginForm(false); // Close the login form on successful login
        // You might want to show a notification or perform some action on successful login.
    } else {
        alert('Invalid credentials.'); // Alert the user or show some error message
        // You can also reset the form fields if needed
    }
};

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box display="flex">
     <img src="/hotwheels.png" alt="Hot Wheel" style={{ height: '40px', marginRight: '10px' }} />
        <img src="/matchbox.png" alt="Matchbox" style={{ height: '40px', marginRight: '10px' }} />
        <img src="/johnnylightning.png" alt="Johnny Lightning" style={{ height: '40px' }} />
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        
        {/* Conditional rendering for Notifications Icon */}
  {isAdmin && (
    zeroValuesInfo.length > 0 ? (
      <div style={{ position: 'relative' }}>
        <IconButton onClick={toggleMessageBox}>
          <NotificationsOutlinedIcon />
        </IconButton>
        <div
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            background: 'red',
            color: 'white',
            borderRadius: '50%',
            width: '16px',
            height: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
          }}
        >
          {zeroValuesInfo.length}
        </div>
      </div>
    ) : (
      <IconButton>
        <NotificationsOutlinedIcon />
      </IconButton>
    )
  )}

<IconButton onClick={toggleLoginForm}>
            <PersonOutlinedIcon />
        </IconButton>
        {showLoginForm && (
    <div style={{position: 'absolute', top: '40px', right: '10px', background: 'white', padding: '10px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px'}}>
<form onSubmit={handleLogin}>
            <div>
                <input type="text" placeholder="Username" style={{marginBottom: '10px', padding: '5px', width: '200px'}} />
            </div>
            <div>
                <input type="password" placeholder="Password" style={{marginBottom: '10px', padding: '5px', width: '200px'}} />
            </div>
            <button type="submit">Login</button>
        </form>
    </div>
)}


      </Box>
      {isAdmin &&showMessageBox && (
        <MessageBox
          message={notificationMessage}
          onClose={toggleMessageBox}
        />
      )}
    </Box>
  );
};

export default Topbar;

