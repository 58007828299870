import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';

const renderAdvanceColumn = (params) => {
  const value = params.value;

  if (value === 0) {
    return 'No';
  } else if (value === 1) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'green' }}></div>
      </div>
    );
  }

  return ''; // Return an empty string for other cases
};

const renderRoundColumn = (params) => {
  const roundValue = params.value;

  if (roundValue === 3) {
    // Render the goldtrophy.png image
    return (
      <img
        src="/goldtrophy.png" // Assuming the image is in the public folder
        alt="Gold Trophy"
        style={{ width: '16px', height: '16px' }}
      />
    );
  }

  return roundValue; // Render the regular round value for other cases
};

const LastTenTable = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Format the time values to have a maximum of 3 decimal places
  const formattedData = data.map((row) => ({
    ...row,
    Time: row.Time ? parseFloat(row.Time).toFixed(3) : null,
  }));

  const columns = [
    { field: 'Date', headerName: 'Date', width: 130 },
    { field: 'tourneyid', headerName: 'Tourney ID', width: 130 },
    { field: 'Time', headerName: 'Time', width: 130 },
    { 
      field: 'MedianDiff', 
      headerName: '+/- to Average', 
      width: 130, 
      renderCell: (params) => {
        const value = parseFloat(params.value);
        if (!isNaN(value)) {
          let arrow = '';
          let arrowColor = '';
      
          if (value < 0) {
            arrow = '↓';
            arrowColor = 'green';
          } else if (value > 0) {
            arrow = '↑';
            arrowColor = 'red'; // Set the arrow color to red for positive values
          }
      
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {arrow && (
                <div
                  style={{
                    color: arrowColor,
                    marginRight: '5px',
                    fontSize: '1.2em',
                  }}
                >
                  {arrow}
                </div>
              )}
              {value.toFixed(3)}
            </div>
          );
        }
        return '';
      },
    },
    { field: 'Lane', headerName: 'Lane', width: 130 },
    {
      field: 'Round',
      headerName: 'Round',
      width: 130,
      renderCell: renderRoundColumn, // Use custom renderer for Round
    },
    {
      field: 'Advance',
      headerName: 'Won Race?',
      width: 130,
      renderCell: renderAdvanceColumn,
    },
    {
      field: 'Rank',
      headerName: "Placed",
      width: 130,
    }
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <h2 style={{ marginBottom: '15px', marginLeft: '15px' }}>Last 10 Runs</h2>
      <DataGrid
        rows={formattedData}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            display: "none"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            width: "0px",
          },
        }}
      />
    </div>
  );
};

export default LastTenTable;
